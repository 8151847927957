import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import NotFound from "../pages/404";
import ArticleItem from "../components/ArticleItem";
import ContainerWithCenteredColumns from "../components/ContainerWithCenteredColumns";

class TagRoute extends React.Component {
  render() {
    if (!this.props.data.allMarkdownRemark) return <NotFound />;

    const posts = this.props.data.allMarkdownRemark.edges;
    const tag = this.props.pageContext.tag;
    const title = this.props.data.site.siteMetadata.title;
    const tagHeader = tag;

    return (
      <Layout>
        <section className="section">
          <Helmet title={`${tag} | ${title}`} />
          <ContainerWithCenteredColumns>
            <div className="column is-8" style={{ marginBottom: "6rem" }}>
              <h2
                style={{
                  marginBottom: 32,
                  marginTop: 0,
                  alignSelf: "center",
                  textTransform: "capitalize"
                }}
                className="has-text-weight-bold is-size-4"
              >
                {tagHeader}
              </h2>
              {posts.map(({ node: post }) => (
                <ArticleItem key={post.frontmatter.title} post={post} />
              ))}
            </div>
          </ContainerWithCenteredColumns>
        </section>
      </Layout>
    );
  }
}

export default TagRoute;

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] }, draft: { eq: false } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            description
            date(formatString: "MMMM DD, YYYY")
            author
            featuredImage {
              childImageSharp {
                sizes(maxWidth: 900) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
        }
      }
    }
  }
`;
